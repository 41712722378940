/* Bootstrap Variables */
/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/
/* Common Style */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.7333;
  position: relative;
  visibility: visible;
  overflow-x: hidden;
  color: #515151;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: top center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 8px;
  color: #000000;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: inherit;
  margin: 0;
}

p {
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}

a,
button {
  line-height: inherit;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

a,
button,
input {
  transition: all 0.4s ease 0s;
}

*:focus {
  outline: none;
}

a:focus {
  text-decoration: none;
  color: inherit;
  outline: none;
}

a:hover {
  text-decoration: none;
  color: #5463FF;
}

button,
input[type=submit] {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
}

ul {
  margin-top: 0;
}
ul:last-child {
  margin-bottom: 0;
}

hr {
  border-top-width: 2px;
}

.bg-light {
  background-color: #F3F3F9 !important;
}

.block-title {
  margin-bottom: 60px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .block-title {
    margin-bottom: 30px;
  }
}

.section {
  padding: 100px 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section {
    padding: 80px 0;
  }
}
@media only screen and (max-width: 767px) {
  .section {
    padding: 60px 0;
  }
}